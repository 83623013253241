import { WarningOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Alert, Button, Checkbox, Form, Input, Modal } from 'antd';
import MaskedInput from 'antd-mask-input';
import { Link } from 'gatsby';
import React, { FC, useState } from 'react';
import { useActiveModal } from '../../hooks';
import { useBackendMutation } from '../../hooks/useBackendMutation';
import { extractGraphqlErrorMessages, validateOnce } from '../../utils';
import formStyles from '../formComponents/formComponents.module.css';
import styles from './register.module.css';

interface FormData {
  email: string;
  password: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
};

type RegisterProps = {
  initialValues: Partial<FormData>;
};

const REGISTER = gql`
  mutation register($input: RegisterInput!) {
    register(input: $input) {
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

const Register: FC<RegisterProps> = ({ initialValues }) => {
  const [page, setPage] = useState('first');
  const { setActiveModal } = useActiveModal();

  const [register] = useBackendMutation(REGISTER);
  const [errors, setErrors] = useState([] as any);
  const [form] = Form.useForm();

  const onSubmit = async ({ firstName, lastName, phoneNumber, email, password }: FormData) => {
    try {
      await register({
        variables: {
          input: {
            firstName,
            lastName,
            email,
            phoneNumber,
            password,
          }
        }
      });
      setActiveModal('confirm-sent');
    } catch (exception) {
      const error = extractGraphqlErrorMessages(exception);
      error.register ? setErrors(error.register.codes) : setErrors(['other']);
      form.validateFields();
    }
  };

  return (
    <Form
      className={formStyles.form}
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={(data) => { onSubmit(data as FormData) }}
    >
      <div className={page !== 'first' ? styles.hidden : undefined}>
        <h3><span className="turquoise">Vertel ons wie je bent</span></h3>

        <Form.Item
          label="Wat is je voornaam?"
          name="firstName"
          rules={[{ required: true, message: 'Vul je voornaam in' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Wat is je achternaam?"
          name="lastName"
          rules={[{ required: true, message: 'Vul je achternaam in' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Wat is je mobiele telefoonnummer?"
          name="phoneNumber"
          rules={[{ required: true, message: 'Vul je telefoonnummer in' }]}
        >
          <MaskedInput mask="1111111111" placeholderChar=" " />
        </Form.Item>

        <Form.Item
          className="form-item-checkbox"
          name="privacy"
          valuePropName="checked"
          rules={[
            { validator: (_, value) => value ? Promise.resolve() : Promise.reject('Ga akkoord met onze privacyverklaring') },
          ]}
        >
          <Checkbox>
            Ik ga akkoord met de privacyverklaring<br />
            <small>Informatie over voorwaarden. <Link to="/privacy-policy">Privacy Statement</Link></small>
          </Checkbox>
        </Form.Item>
        <Form.Item style={{ marginTop: '40px' }}>
          <Button
            type="primary"
            block
            onClick={async () => {
              const result = await form.validateFields(['username', 'phoneNumber', 'privacy'])
                .then(() => true)
                .catch(() => false);
              if (result) setPage('second');
            }}
          >
            Volgende
          </Button>
        </Form.Item>
        <p style={{ textAlign: 'center' }}>
          Heb je al een account? <a onClick={() => setActiveModal('login')}>Inloggen</a>
        </p>
      </div>

      <div className={page !== 'second' ? styles.hidden : undefined}>
        <h3><span className="turquoise">Veiligheid boven alles</span></h3>
        {errors.includes('other') && <Alert type="error" message="Er is iets misgegaan bij je registratie." showIcon icon={<WarningOutlined />} />}
        {errors.includes('MessageRejected') && <Alert type="error" message="Het versturen van je registratiemail is mislukt." showIcon icon={<WarningOutlined />} />}
        <Form.Item
          label="Wat is je e-mailadres?"
          name="email"
          rules={[
            { required: true, message: 'Vul je e-mailadres in' },
            { type: 'email', message: 'Dit lijkt niet op een e-mailadres' },
            validateOnce(errors, setErrors, 'Auth.form.error.email.provide', 'Vul je e-mailadres in'),
            validateOnce(errors, setErrors, 'Auth.form.error.email.format', 'Dit lijkt niet op een e-mailadres'),
            validateOnce(errors, setErrors, 'Auth.form.error.email.taken', 'Dit e-mailadres is al in gebruik'),
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Kies een wachtwoord"
          rules={[
            { required: true, message: 'Kies een wachtwoord' },
            { pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{12,}$/, message: 'Je wachtwoord is niet complex genoeg' },
            validateOnce(errors, setErrors, 'Auth.form.error.password.format', 'Je wachtwoord mag niet meer dan drie keer het $ symbool bevatten'),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <p style={{ fontSize: '14px', margin: '0 8px' }}>Je wachtwoord dient minimaal 12 karakters te bevatten waarvan klein en grote letters, en tenminste één cijfer en speciaal teken.</p>
        <Form.Item style={{ marginTop: '40px' }}>
          <Button
            type="primary"
            htmlType="submit"
            block
          >
            Maak account aan
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

const RegisterModal = () => {
  const { modal, setActiveModal } = useActiveModal();
  const [modalName, email] = modal?.split(':') || [];

  return (
    <Modal
      bodyStyle={{ backgroundColor: 'var(--secondary-500)' }}
      destroyOnClose={true}
      footer={null}
      onCancel={() => setActiveModal(null)}
      visible={modalName === 'register'}
      width={500}
    >
      <Register initialValues={{ email }} />
    </Modal>
  );
};

export default RegisterModal;
