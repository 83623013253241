import React from 'react';
import { Row, Col, Button } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import moment from 'moment';
import close from '../../images/close.svg';
import { Education, EducationalInstitution } from '../../utils';
import styles from './item.module.css';
import { useBackendQuery } from '../../hooks/useBackendQuery';

export interface StudyItemProps {
  item: {
    education: string,
    educationalInstitution: string,
    educationalInstitutionName: string,
    educationName: string,
    finished: boolean,
    beginDate: moment.Moment,
    endDate: moment.Moment,
    type: string,
  },
  onClick: () => void,
  destroyItem: () => void,
};

interface EducationData {
  education: Education,
};

interface EducationalInstitutionData {
  educationalInstitution: EducationalInstitution,
};

const EDUCATION = gql`
  query($id: ID!) {
    education(id: $id) {
      name
    }
  }
`;

const EDUCATIONAL_INSTITUTION = gql`
  query($id: ID!) {
    educationalInstitution(id: $id) {
      name
    }
  }
`;

const StudyItem = ({ item: study, onClick, destroyItem }: StudyItemProps) => {
  const { data: dataEducation } = useBackendQuery<EducationData>(EDUCATION, { variables: { id: study.education } });
  const { data: dataEducationalInstitution } = useBackendQuery<EducationalInstitutionData>(EDUCATIONAL_INSTITUTION, { variables: { id: study.educationalInstitution } });

  return (
    <div className={styles.item} >
      <Row>
        <Col flex="calc(100% - 62px)">
          <h4>{['OTHERWISE', 'ABROAD'].includes(study.type) ? study.educationalInstitutionName : dataEducation?.education?.name}</h4>
          <p>{['OTHERWISE', 'ABROAD'].includes(study.type) ? study.educationName : dataEducationalInstitution?.educationalInstitution?.name}</p>
          {study.finished ?
            <p>Behaald in {study.endDate?.format('MMMM YYYY')}</p>
          : null}
        </Col>
        <Col flex="0 0 60px">
          <a onClick={onClick} className={styles.open}>
            <CaretRightOutlined onClick={onClick} style={{ fontSize: '28px', position: 'relative', top: '3px' }} />
          </a>
          <a className={styles.close} onClick={destroyItem}>
            <img src={close} alt="close-x" />
          </a>
        </Col>
      </Row>

    </div>
  );
};

export default StudyItem;
