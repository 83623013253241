import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import { CaretRightOutlined, LogoutOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { Layout } from '../';
import { useAuth, useEvent } from '../../hooks';
import itemStyles from '../formComponents/item.module.css';
import styles from './account.module.css';

const Account = () => {
  const { me, logout } = useAuth();
  const { tbEvent } = useEvent();

  const studentsCanPickProgramItems = useMemo(() => {
    if (!tbEvent) return {};
    return tbEvent.studentsCanPickProgramItems;
  }, [tbEvent]);
  const currentTbEventEnrollment = (me?.tbEventEnrollments ?? []).find(enrollment => enrollment.tbEvent.id === tbEvent?.id)
  const hasPickedProgramItems =  !studentsCanPickProgramItems || (currentTbEventEnrollment?.preferredProgramItems ?? []).length > 0

  return <Layout>
    <div className={`container-sm ${styles.account}`}>
      {me ? <>
        <h2>Welkom terug, {me.firstName}!</h2>
        <p style={{ margin: 0 }}>Mijn account</p>

        <Row gutter={20}>
          <Col lg={12}>
            <div className={styles.block}>
              <h3><span className="turquoise">{me.firstName} {me.lastName}</span></h3>
              <p><b>{me.email}</b></p>
              <p><b>{me.phoneNumber}</b></p>
            </div>
          </Col>
          <Col lg={12}></Col>

          <Col lg={12}>
            <Link
              style={{ display: 'block' }}
              to="/student/profiel/"
              className={itemStyles.item}>
              <Row>
                <Col flex="calc(100% - 52px)">
                  <h4>Mijn profiel</h4>
                </Col>
                <Col flex="0 0 25px">
                  <CaretRightOutlined style={{ fontSize: '24px', position: 'relative', top: '3px' }} />
                </Col>
              </Row>
              <p>{me.isComplete ? 100 : (me.progress ?? 0)}% compleet</p>
            </Link>
          </Col>

          <Col lg={12}>
            <Link
              style={{ display: 'block' }}
              to={hasPickedProgramItems ? "/student/programma/" : '/student/voor-programma/' }
              className={itemStyles.item}
            >
              <Row>
                <Col flex="calc(100% - 52px)">
                  <h4 className={`${styles.events} ${styles[hasPickedProgramItems ? '' : 'todo']}`}>Mijn TBE</h4>
                </Col>
                <Col flex="0 0 25px">
                  <CaretRightOutlined style={{ fontSize: '24px', position: 'relative', top: '3px' }} />
                </Col>
              </Row>
              <p>voorkeuren {hasPickedProgramItems ? '' : ' nog niet '} opgegeven</p>
            </Link>
          </Col>

          <Col lg={12}>
            <Link
              style={{ display: 'block' }}
              to="/student/inhousedagen/"
              className={itemStyles.item}>
              <Row>
                <Col flex="calc(100% - 52px)">
                  <h4>Mijn inhousedagen</h4>
                </Col>
                <Col flex="0 0 25px">
                  <CaretRightOutlined style={{ fontSize: '24px', position: 'relative', top: '3px' }} />
                </Col>
              </Row>
              <p>{(me?.inhouseDayEnrollments ?? [])?.length} gepland</p>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12} lg={8}>
            <Button
              block
              onClick={logout}
            >
              <LogoutOutlined style={{ fontSize: 25 }} /> <span style={{ position: 'relative', top: -3 }} >Uitloggen</span>
            </Button>
          </Col>
        </Row>
      </>
      : null
      }
    </div>
  </Layout>;
};

export default Account;
