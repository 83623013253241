import { identityFn, WizardStep, DeepPartial } from '../utils';
import { useAuth } from '.';
import { useContext, useMemo, createContext, useState, useEffect, useCallback } from 'react';
import {
  Industries,
  LinkedIn,
  HighSchool,
  Studies,
  SideActivities,
  Jobs,
  Boards,
  Committees,
  TopSport,
  OwnBusiness,
  VolunteerWork,
  StudentTeam,
  Skills,
  Complete,
} from '../components/profileWizard/steps';
import { navigate, useLocation } from '@reach/router';
import { User, UserInput } from '../utils/graphqlTypes/backendTypes';

type Step = { key: WizardStep; component: () => JSX.Element; slug: string };
type StepsConfig = Step[];

const allSteps: StepsConfig = [
  { key: 'INDUSTRIES', component: Industries, slug: 'sectoren' },
  { key: 'LINKED_IN', component: LinkedIn, slug: 'linkedin' },
  { key: 'HIGH_SCHOOL', component: HighSchool, slug: 'middelbare-school' },
  { key: 'STUDIES', component: Studies, slug: 'studie' },
  { key: 'SIDE_ACTIVITIES', component: SideActivities, slug: 'nevenactiviteiten' },
  { key: 'JOBS', component: Jobs, slug: 'werkervaring' },
  { key: 'COMMITTEES', component: Committees, slug: 'commissiewerk' },
  { key: 'BOARDS', component: Boards, slug: 'bestuur' },
  { key: 'TOP_SPORT', component: TopSport, slug: 'topsport' },
  { key: 'OWN_BUSINESS', component: OwnBusiness, slug: 'eigen-bedrijf' },
  { key: 'VOLUNTEER_WORK', component: VolunteerWork, slug: 'vrijwilligerswerk' },
  { key: 'STUDENT_TEAM', component: StudentTeam, slug: 'studententeam' },
  { key: 'SKILLS', component: Skills, slug: 'vaardigheden' },
  { key: 'COMPLETE', component: Complete, slug: 'compleet' },
];

type Maybe<T> = T | undefined;

const GO_TO_NEXT_STEP = 'GO_TO_NEXT_STEP';

const camelize = (text: string, separator = '_') =>
  text.split(separator).reduce((acc, cur) => `${acc}${cur.charAt(0).toUpperCase() + cur.slice(1).toLowerCase()}`, '');

const StepsContext = createContext<{
  steps: StepsConfig;
  currentStep: Step | null;
  setCurrentStep: (step: Step) => void;
}>({
  steps: [],
  currentStep: null,
  setCurrentStep: () => {},
});

export const useSteps = (initialStepSlug?: string) => {
  const { me, updateMe, loading } = useAuth();
  const location = useLocation();

  const steps = useMemo(() => {
    if (!me) return allSteps;

    return allSteps.filter(step => {
      const guard = `has${camelize(step.key)}` as keyof User;
      return me[guard] === true || me[guard] === undefined;
    });
  }, [me]);

  const [currentStep, setCurrentStep] = useState(steps.find(s => s.slug === initialStepSlug) || steps[0]);

  useEffect(() => {
    const slug = location.pathname.split('/').reverse()[0];
    if (slug !== currentStep.slug) {
      navigate(`/student/profiel-invullen/${currentStep.slug}`);
    }
  }, [currentStep]);

  useEffect(() => {
    const slug = location.pathname.split('/').reverse()[0];
    if (slug !== currentStep.slug) {
      const slugStep = steps.find(s => s.slug === slug);
      if (slugStep) {
        setCurrentStep(slugStep);
      }
    }
  }, [location, setCurrentStep]);

  const progress = useMemo(() => {
    return Math.round((100 * (steps.indexOf(currentStep) + 1)) / steps.length);
  }, [steps, currentStep]);

  return {
    StepsContext,
    stepsState: { steps, currentStep, setCurrentStep },
    goToStep: goToStep(updateMe, steps, currentStep),
    hasStep,
    steps,
    currentStep,
    setCurrentStep,
    loading,
    progress,
  };
};

const getStep = (i: number) => {
  const { steps, currentStep } = useContext(StepsContext);
  if (!currentStep) return steps[0];
  return steps[steps.indexOf(currentStep) + i];
};

const goToStep = (updateMe: Function, steps: StepsConfig, currentStep: Step): Function => {
  return (i: number) => {
    const step = currentStep ? steps[steps.indexOf(currentStep) + i] : steps[0];
    updateMe({
      variables: {
        input: {
          step: step.key,
        },
      },
    });
    navigate(step.slug);
  };
};

const hasStep = (i: number): boolean => {
  return !!getStep(i);
};

export const useNextStep = () => getStep(1);

export const usePreviousStep = () => getStep(-1);

export const useCurrentStep = () => {
  const { currentStep } = useContext(StepsContext);
  return currentStep;
};

export const useProgress = (offset: number) => {
  const { steps, currentStep } = useContext(StepsContext);
  if (!currentStep) return 0;
  return Math.round((100 * (steps.indexOf(currentStep) + 1 + offset)) / steps.length);
};

const useStep = <T>(
  transformInput: (me: User) => Maybe<T>,
  transformOutput: (formData: T) => DeepPartial<UserInput> = identityFn
) => {
  const { me, updateMe } = useAuth();
  const { currentStep } = useContext(StepsContext);
  const [navigateToNextStep, setNavigateToNextStep] = useState<Step | null>(null);

  const initialValues = useMemo(() => {
    if (!me) return me;
    return transformInput(me);
  }, [me]);

  useEffect(() => {
    setTimeout(() => {
      if (navigateToNextStep) {
        navigate(navigateToNextStep?.slug);
        setNavigateToNextStep(null);
      }
    }, 500)
  }, [navigateToNextStep]);

  const progress = useProgress(1);
  const currentNextStep = useNextStep();

  const onFinish = async (formData: T, toNextStepAfterUpdate = false) => {
    const inputData = transformOutput(formData);

    const steps = allSteps.filter(step => {
      const guard = `has${camelize(step.key)}` as keyof User;
      return inputData[guard] === true || inputData[guard] === undefined;
    });
    const nextStepAfterUpdate = currentStep ? steps[steps.indexOf(currentStep) + 1] : steps[0];
    const nextStep = toNextStepAfterUpdate ? nextStepAfterUpdate : currentNextStep;

    const { data } = await updateMe({
      variables: {
        input: {
          ...inputData,
          step: nextStep.key,
          progress,
        },
      },
    });

    setNavigateToNextStep(nextStep);
  };

  return { initialValues, onFinish, nextStep: currentNextStep };
};

export default useStep;
