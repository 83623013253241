import React from 'react';
import {
  Form, Input, InputNumber, Button, Checkbox,
} from 'antd';
import { useStep } from '../../../hooks';
import { pick, VolunteerWork as VolunteerWorkType } from '../../../utils';
import formStyles from '../../formComponents/formComponents.module.css';
import ItemsOverview from '../../formComponents/itemsOverview';
import VolunteerWorkItem from '../../formComponents/volunteerWorkItem';
import useLinkedForms from '../../../hooks/useLinkedForms';
import VolunteerWorkModal from './volunteerWorkModal';

interface FormData {
  volunteerWork: Partial<VolunteerWorkType>[]
}

const VolunteerWork = () => {
  const { initialValues, onFinish } = useStep<FormData>(
    (me) => ({ volunteerWork: (me.volunteerWork ?? []).map((volunteerWork) => ({
      ...pick(volunteerWork, ['id', 'associationName', 'position', 'hoursPerMonth', 'partiallyAbroad']) }))
    }),
    ({ volunteerWork }) => ({
      volunteerWork: (volunteerWork ?? []).map((work) => ({
        ...work,
        hoursPerMonth: Number.parseInt(work.hoursPerMonth as string, 10),
      })),
    }),
  );

  const {
    form,
    linkedForm,
    onFormFinish,
    openEdit,
    openNew,
    shouldUpdate,
    destroyItem,
  } = useLinkedForms<FormData['volunteerWork']>('volunteerWork', onFinish);

  return (

    <Form.Provider onFormFinish={onFormFinish}>
      <Form
        {...form}
        className={formStyles.form}
        initialValues={initialValues}
      >
        <h3><span className="turquoise">Vrijwilligerswerk</span></h3>

        <p>
          Vertel ons wat voor vrijwilligerswerk je gedaan hebt.
          {' '}
          <u>Begin bij je meest relevante ervaring.</u>
        </p>

        <ItemsOverview
          field="volunteerWork"
          name={{ singular: 'Vrijwilligerswerk', plural: 'vrijwilligerswerk ervaringen' }}
          ItemComponent={VolunteerWorkItem}
          {...{ openEdit, openNew, shouldUpdate }}
          destroyItem={destroyItem}
        />
      </Form>

      <VolunteerWorkModal
        {...linkedForm}
      />

    </Form.Provider>
  );
};

export default VolunteerWork;
