import React from 'react';
import { Row, Col } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import close from '../../images/close.svg';
import { VolunteerWork } from '../../utils';
import styles from './item.module.css';

export interface VolunteerWorkItemProps {
  item: VolunteerWork,
  onClick: () => void,
  destroyItem: () => void,
}

const VolunteerWorkItem = ({ item: study, onClick, destroyItem }: VolunteerWorkItemProps) => (
  <div className={styles.item}>
    <Row>
      <Col flex="calc(100% - 62px)">
        <h4>{study.associationName}</h4>
        <p>{study.position}</p>

        {study.hoursPerMonth}
        {' '}
        uur per maand
        {study.partiallyAbroad && <p>Gedeeltelijk in het buitenland</p>}
      </Col>
      <Col flex="0 0 60px">
        <a onClick={onClick} className={styles.open}>
          <CaretRightOutlined onClick={onClick} style={{ fontSize: '28px', position: 'relative', top: '3px' }} />
        </a>
        <a className={styles.close} onClick={destroyItem}>
          <img src={close} alt="close-x" />
        </a>
      </Col>
    </Row>

  </div>
);

export default VolunteerWorkItem;
