import { Button } from 'antd';
import { Link } from 'gatsby';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Layout, Breadcrumb } from '..';
import { useAuth, useMicrocopy  } from '../../hooks';
import { InhouseDay, Participation, dateIsBeforeNow, Enrollment, User } from '../../utils';
import cv from '../../images/cv.png';
import lock from '../../images/lock.png';
import cup from '../../images/cup.png';
import warningSign from '../../images/warning-sign.png';
import styles from './myInhouseIndex.module.css';
import { gql, useQuery } from '@apollo/client';
import { toIdDictionary } from '../../utils/toIdDictionary';

interface Day extends InhouseDay {
  participation: Participation
}

const getEnrollmentState = (enrollment: Enrollment, me?: User) => {
  if (enrollment.participation === 'CONFIRMED') return 'selected';
  if (enrollment.participation === 'DENIED') return 'not selected';
  if (dateIsBeforeNow(enrollment.inhouseDay.finalSignupDate) && !(me?.cv?.id)) return 'send cv'
  return 'closed';
};

const byStartDate = (a: { startDate: Date }, b: { startDate: Date }) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()

const eventsIndex = () => {
  const { me } = useAuth();
  const m = useMicrocopy();
  const { data: strapiInhouseDaysData } = useQuery(gql`
    query {
      inhouseDays {
        id
        slug
      }
    }
  `)

  const inhouseDays = useMemo(() => {
    const enrollments = me?.inhouseDayEnrollments ?? []
    const strapiInhouseDays = toIdDictionary(strapiInhouseDaysData?.inhouseDays ?? [])

    return enrollments?.map((enrollment) => ({
      ...strapiInhouseDays[enrollment?.inhouseDay?.strapiId],
      ...enrollment.inhouseDay,
      state: getEnrollmentState(enrollment, me)
    })).sort(byStartDate)
  }, [me, strapiInhouseDaysData]);


  const replaceInhouseDays = ((day: any, microKey: any) => {
    const microString = m(microKey);
    return microString?.replace(/{(\w*)}/g, (_, match) => {
      switch(match) {
        case 'Title': return day.title;
        case 'FinalSignupDate': return moment(day.finalSignupDate).format('DD MMMM');
        case 'Time': return day.time;
        case 'Place': return day.location;
        default: return match;
      }
    });
  });

  return <Layout>
    <div className={`${styles.myInhouseIndex} container-xs`}>
      <Breadcrumb />
      <h2>MIJN INHOUSEDAGEN</h2>

      {inhouseDays?.length ? <>
          {inhouseDays.map(day => {
            return <div className={styles.day} key={day.id}>
                <h3>
                  <span className="turquoise">
                    {moment(day.startDate).format('dddd DD MMMM YYYY')}
                    {day.endDate && ` t/m ${moment(day.endDate).format('dddd DD MMMM YYYY')}`}
                  </span>
                </h3>
                <p>{day.title} | {day.company.name}</p>
                <p>{day.time}</p>
                <p>{day.location}</p>

                {day.state === 'selected' && <div className={styles.actionPanel}>
                  <p><img src={cup} /> <b>{replaceInhouseDays(day, 'InhouseDaySelectedTitle')}</b></p>
                  <p>{n('InhouseDaySelectedDescription')}</p>
                </div>}

                {day.state === 'not selected' && <div className={styles.actionPanel}>
                  <p><img src={warningSign} /> <b>{replaceInhouseDays(day, 'InhouseDayNotSelectedTitle')}</b></p>
                  <p>{replaceInhouseDays(day, 'InhouseDayNotSelectedDescription')}</p>
                </div>}

                {day.state === 'send cv' && <div className={styles.actionPanel}>
                  <p><img src={cv} /> <b>{replaceInhouseDays(day, 'InhouseDaySendCvTitle')}</b></p>
                  <p>{replaceInhouseDays(day, 'InhouseDaySendCvDescription')?.replace(/{inhouseFinalSignupDate}/g, moment(day.finalSignupDate).format('DD MMMM'))}</p>
                </div>}

                {day.state === 'closed' && <div className={styles.actionPanel}>
                  <p><img src={lock} /> <b>{replaceInhouseDays(day, 'InhouseDayClosedTitle')}</b></p>
                  <p>{replaceInhouseDays(day, 'InhouseDayClosedDescription')}</p>
                </div>}

                <Button>
                  <Link to={`/inhousedagen/${day.slug}`}>
                    Bekijk de inhousedag
                  </Link>
                </Button>
              </div>
            })
          }
          <p className={styles.allDays}><Link to="/inhousedagen/">Bekijk alle inhousedagen</Link></p>
        </>
      : <div>
        <p style={{ margin: '20px 0 40px' }}>Hier kun je je toekomstige activiteiten inzien.</p>
        <p className={styles.allDays} style={{ marginBottom: 300 }}><Link to="/inhousedagen/">Bekijk alle inhousedagen</Link></p>
      </div>
      }

    </div>
  </Layout>
};

export default eventsIndex;
