import { WarningOutlined } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { useLocation } from '@reach/router';
import { Alert, Button, Col, Row } from 'antd';
import { Link } from 'gatsby';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Layout } from '../';
import { useAuth } from '../../hooks';
import { useBackendMutation } from '../../hooks/useBackendMutation';

const EMAIL_CONFIRMATION = gql`
  mutation emailConfirmation($confirmation: String!) {
    emailConfirmation(confirmation: $confirmation) {
      jwt
    }
  }
`;

const ConfirmEmail = () => {
  const { me, setToken } = useAuth();
  const [emailConfirmation] = useBackendMutation(EMAIL_CONFIRMATION);
  const [error, setError] = useState(false);

  const location = useLocation();
  const { confirmation } = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (!confirmation) {
      setError(true)
      return
    }
    emailConfirmation({ variables: { confirmation } })
      .then(({ data }) => {
        setToken(data.emailConfirmation.jwt);
      })
      .catch((_) => {
        setError(true);
      });

  }, [confirmation]);

  return (
    <Layout>
      <section style={{ padding: '32px 0' }}>
        <div className="container">
          <Row>
            <Col md={{ span: 12, offset: 6 }}>
              {me ?
                <>
                  <h3><span className="turquoise">Lekker bezig {me.firstName}!</span></h3>
                  <p>Welkom bij de club. Maak snel je profiel aan zodat we de beste matches voor jou kunnen vinden. Het is zo gepiept!</p>
                  <p>Je antwoorden worden in je account opgeslagen.</p>
                  <p style={{ fontSize: '64px', textAlign: 'center' }}>🥳</p>
                  <Button type="primary" block style={{ margin: '40px 0 0 0' }}>
                    <Link to="/student/deelname-aanmelden/">Aan de slag!</Link>
                  </Button>
                </> :
                <>
                  <h3><span className="turquoise">E-mailadres controleren</span></h3>
                  {error && <Alert type="error" message="Het bevestigen van je account is mislukt." showIcon icon={<WarningOutlined />} />}
                  <p></p>
                </>
              }
            </Col>
          </Row>
        </div>
      </section>
    </Layout>
  );
};

export default ConfirmEmail;
