import { Button } from 'antd';
import React from 'react';
import starx3 from '../../images/starx3.png';
import { PickerHeader } from '../';
import styles from './prePicker.module.css';
import moment from 'moment';
import gql from 'graphql-tag';
import { useBackendQuery } from '../../hooks/useBackendQuery';
import { useEvent } from '../../hooks';

const PreEventsPicker = () => {
  const { tbEvent } = useEvent()
  const { data } = useBackendQuery(gql`
    query {
      currentTBEvent {
        programItemTypeCount
      }
    }
  `);

  return <div className={styles.preEventsPicker}>
    <PickerHeader />

    <div className="container-sm">
      <h3><span className="turquoise">Toppertje</span></h3>
      <p>Het {tbEvent?.name} op {moment(tbEvent?.date).format('DD MMMM')} zal bestaan uit {data?.currentTBEvent?.programItemTypeCount} dagonderdelen. We vragen je nu om per dagonderdeel je voorkeur uit te spreken.</p>
      <p>Ook vragen we je om je adres in te vullen, zodat we eventuele merchandise van de deelnemende bedrijven naar je kunnen sturen.</p>
      <p>Doe dit vóór {moment(tbEvent?.finalSignupDate).format('DD MMMM')}, dan gaan wij daarna aan de slag om de beste match voor jou te vinden!</p>

      <p className={styles.center}>
        <img src={starx3} alt="star" />
      </p>
      <p className={styles.center}>
        <Button
          type="primary" href="/student/programma-invullen/"
          block
          style={{ maxWidth: 400 }}>
          Aan de slag
        </Button>
      </p>
    </div>
  </div>
};

export default PreEventsPicker;
