import { gql, useMutation, useQuery } from "@apollo/client";
import { Col, Row } from "antd";
import { Link, navigate } from "gatsby";
import moment from "moment";
import React, { useMemo } from 'react';
import { InhouseDay, Layout } from "../";
import LogoCircle from "../../images/logo-circle.svg";
import { InhouseDayProps, TBEventProps } from "../../utils/graphqlTypes/strapiTypes";
import { tbEventOpenForSignup } from '../../utils/tbEvent';
import styles from "./enroll.module.css";
import { useBackendMutation } from "../../hooks/useBackendMutation";
import { useBackendQuery } from "../../hooks/useBackendQuery";
import { useEvent } from "../../hooks";
import { toIdDictionary } from "../../utils/toIdDictionary";

interface EventData {
  inhouseDays: [InhouseDayProps]
}

const EVENTS = gql`
  query {
    inhouseDays {
      id
      title
      slug
      company {
        name
        logo {
          url
        }
      }
      image {
        url
      }
    }
  }
`

const BACKEND_INHOUSE_DAYS = gql `
  query {
    enrollableInhouseDays {
      id
      name
      company {
        name
      }
      strapiId
    }
  }
`



const ENROLL = gql`
  mutation enroll($input: EnrollUserInput!) {
    enroll(input: $input) {
      id
    }
  }
`

const Enroll = () => {
  const { tbEvent } = useEvent();
  const { data: dataEvents } = useQuery<EventData>(EVENTS)
  const strapiEventDictionary = toIdDictionary(dataEvents?.inhouseDays ?? []);

  const { data: backendInhouseDaysData } = useBackendQuery(BACKEND_INHOUSE_DAYS)

  const tbEventOpenToEnroll = useMemo(
    () => Boolean(tbEventOpenForSignup(tbEvent) && tbEvent?.active),
    [tbEvent]
  );


  const inhouseDays = (backendInhouseDaysData?.enrollableInhouseDays ?? []).flatMap((inhouseDay)=> {
    const strapiInhouseDay = strapiEventDictionary[inhouseDay.strapiId]
    if (!strapiInhouseDay) {
      console.log((dataEvents?.inhouseDays ?? []).map(e => e.id), inhouseDay.strapiId)
      return []
    }
    return [{
      ...strapiInhouseDay,
    company: {
      ...strapiInhouseDay.company,
      name: inhouseDay.company.name,
    },
    title: inhouseDay.name, id: inhouseDay.id
  }]})


  function navigateToProfile() {
    navigate("/student/account/")
  }

  const [enroll] = useBackendMutation(ENROLL, { onCompleted: navigateToProfile })
  const onEnroll = async () => {
    if (!tbEvent || !tbEventOpenToEnroll) {
      return
    }

    await enroll({
      variables: {
        input: {
          type: 'TB_EVENT',
          id: tbEvent.id,
        },
      },
    })
  }

  return (
    <Layout>
      <section style={{ padding: "32px 0" }}>
        <div className="container">
          <h3>
            <span className="turquoise">Meld je aan</span>
          </h3>
          <p>Klik op het evenement waarvoor je je wilt aanmelden.</p>

          {tbEvent ? (
            <Row gutter={32}>
              <Col key={`tb-event-${tbEvent.id}`} xs={12} sm={8} md={6}>
                <div
                  className={`${styles.enrollLink} ${
                    tbEventOpenToEnroll ? null : styles.inactiveEvent
                  }`}
                  onClick={onEnroll}
                >
                  <figure className={styles.enrollTile}>
                    <img
                      src={LogoCircle}
                      alt={tbEvent.title}
                      className={tbEventOpenToEnroll ? null : styles.inactiveImage}
                    />
                    {tbEventOpenToEnroll ? null : (
                      <span className={styles.ribbon}>Binnenkort</span>
                    )}
                  </figure>
                  <strong>{tbEvent.title}</strong>
                </div>
              </Col>
            </Row>
          ) : null}

          {inhouseDays?.length ?? 0 > 0 ? (
            <>
              <h4>Inhousedagen</h4>

              <Row gutter={32}>
                {inhouseDays.map(inhouseDay => (
                    <Col key={inhouseDay.id} xs={12} sm={8} md={6}>
                      <Link
                        to={`/inhousedagen/${inhouseDay.slug}/enroll`}
                        className={styles.enrollLink}
                      >
                        <figure className={styles.enrollTile}>
                          <img
                            src={inhouseDay.company.logo.url}
                            alt={`${inhouseDay.title} | ${inhouseDay.company.name}`}
                          />
                        </figure>
                        <strong>{`${inhouseDay.title} | ${inhouseDay.company.name}`}</strong>
                      </Link>
                    </Col>
                  ))}
              </Row>
            </>
          ) : null}
        </div>
      </section>
    </Layout>
  )
}

export default Enroll
