import { WarningOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Divider, Form, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useActiveModal, useAuth } from '../../hooks';
import formStyles from '../formComponents/formComponents.module.css';
import styles from './loginModal.module.css';

interface FormData {
  identifier: string;
  password: string;
};

const Login = () => {
  const { setActiveModal, onFinish } = useActiveModal();
  const { login } = useAuth();
  const [error, setError] = useState(false);

  const onSubmit = async (input: FormData) => {
    try {
      await login({ variables: { input: { password: input.password, email: input.identifier } } });
      onFinish?.();
    } catch (error) {
      setError(true);
    }
  };

  return (
    <Form
      className={formStyles.form}
      layout="vertical"
      onFinish={(data) => { onSubmit(data as FormData) }}
      >
      <h3><span className="turquoise">Even inloggen en door</span></h3>
      {error && <Alert type="error" message="Je e-mailadres of wachtwoord is onjuist." showIcon icon={<WarningOutlined />} />}

      <Form.Item
        label="E-mailadres"
        name="identifier"
        rules={[{ required: true, message: 'Vul je e-mailadres in' }, { type: 'email', message: 'Dit lijkt niet op een e-mailadres' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Wachtwoord"
        name="password"
        rules={[{ required: true, message: 'Vul je wachtwoord in' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
        >
          Inloggen
        </Button>
      </Form.Item>
      <p style={{ textAlign: 'center' }}>
        <a onClick={() => setActiveModal('forgot-password') }>Wachtwoord vergeten?</a>
      </p>
    </Form>
  );
};

const Register = () => {
  const { setActiveModal } = useActiveModal();

  const onSubmit = async ({ identifier }: FormData) => {
    setActiveModal(`register:${identifier}`);
  };

  return (
    <Form
      className={formStyles.form}
      layout="vertical"
      onFinish={(data) => onSubmit(data as FormData) }
      >
      <h3><span className="turquoise">Nieuw bij Techniek Bedrijven?</span></h3>

      <Form.Item
        label="E-mailadres"
        name="identifier"
        rules={[{ required: true, message: 'Vul je e-mailadres in' }, { type: 'email', message: 'Dit lijkt niet op een e-mailadres' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
        >
          Maak een account aan
        </Button>
      </Form.Item>
    </Form>
  );
};

const LoginModal = () => {
  const { modal, onCancel } = useActiveModal();

  return (
    <Modal
      bodyStyle={{ backgroundColor: 'var(--secondary-500)' }}
      destroyOnClose={true}
      footer={null}
      onCancel={() => onCancel?.()}
      visible={modal === 'login'}
      width={500}
      className={styles.loginModal}
    >
      <Row>
        <Col lg={11}>
          <Login />
        </Col>
        <Col xs={0} lg={2}>
          <Divider type="vertical" className={styles.divider} />
        </Col>
        <Col lg={11}>
          <Register />
        </Col>
      </Row>
    </Modal>
  );
};

export default LoginModal;
