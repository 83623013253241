
import React, { useEffect, useState } from 'react';
import { Form, Checkbox, Button, Row, Col, Radio, Alert  } from 'antd';
import { useFormFieldProvider, useStep } from '../../../hooks';
import { UserSkill } from '../../../utils';
import { gql } from '@apollo/client';
import formStyles from '../../formComponents/formComponents.module.css';
import { WarningOutlined } from '@ant-design/icons';
import { useBackendQuery } from '../../../hooks/useBackendQuery';

interface FormData {
  skills: number[]
};

const SKILLS = gql`
  query {
    skills(orderBy: {name: NAME, order: ASC}) {
      id
      name
    }
  }
`;

const Skills = () => {
  const [error, setError] = useState(false);
  const [form] = Form.useForm();
  const { onFieldsChange, useFormField } = useFormFieldProvider(form);
  const { loading, data } = useBackendQuery<{ skills: UserSkill[] }>(SKILLS);
  const optionalSkills = data && data.skills;
  const [skills] = useFormField(['skills']);

  useEffect(() => {
    skills && skills.length > 3 ? setError(true) : setError(false);
  }, [skills]);

  const { initialValues, onFinish } = useStep<FormData>(
    (me) => ({
      skills: (me.skills ?? []).map(skill => skill.id) || [],
    }),
  )

  const finish = (values) => {
    if (error) return;
    onFinish(values as FormData);
  };

  return (
    <Form
      className={formStyles.form}
      initialValues={initialValues}
      onFinish={finish}
      onFieldsChange={onFieldsChange}
    >
      <h3><span className="turquoise">Vaardigheden?</span></h3>
      {error && <Alert type="error" message="Kies er 3 of minder" showIcon icon={<WarningOutlined />} />}

      {!loading && <Form.Item name="skills" label="Wat zijn de drie belangrijkste vaardigheden die je naast je studie hebt opgedaan?">
        <Checkbox.Group className="checkbox-normal">
          <Row gutter={10}>
            {optionalSkills?.map((skill, i) => <Col key={i} lg={12}>
              <Checkbox value={skill.id}>{skill.name}</Checkbox>
            </Col>)}
          </Row>
        </Checkbox.Group>
      </Form.Item>}

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          >
          Opslaan
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Skills;
