import { gql } from '@apollo/client';
import { navigate } from '@reach/router';
import moment from 'moment';
import { useMemo } from 'react';
import { readJWT, writeJWT } from '../utils';
import { User, UserInput } from '../utils/graphqlTypes/backendTypes';
import { useBackendMutation } from './useBackendMutation';
import { useBackendQuery } from './useBackendQuery';

interface MeData {
  me: User;
}

const ME = gql`
  query {
    me {
      id
      email
      firstName
      lastName
      phoneNumber
      postalCode
      streetName
      houseNumber
      city
      qompasVacancyGuide
      qompasCvCheck
      # cv {
      #   id
      # }
      step
      progress
      isComplete
      interestingIndustries {
        id
        name
      }
      highSchoolAverageMark
      highSchoolStartYear
      highSchoolEndYear
      highSchoolName
      highSchoolType
      linkedInProfile
      studies {
        id
        type
        education {
          id
          name
        }
        educationName
        educationalInstitution {
          id
          name
        }
        educationalInstitutionName
        finished
        beginDate
        endDate
        numberOfECs
        partiallyAbroad
        averageMark
        minor
        nominal
        monthsBehind
      }
      hasJobs
      hasCommittees
      hasBoards
      hasTopSport
      hasOwnBusiness
      hasVolunteerWork
      hasStudentTeam
      jobs {
        id
        companyName
        jobTitle
        type
        beginDate
        endDate
        hoursPerWeek
        partiallyAbroad
      }
      committees {
        id
        association
        name
        position
        employmentType
        beginDate
        endDate
        hoursPerWeek
      }
      boards {
        id
        type
        name
        position
        employmentType
        beginDate
        endDate
        hoursPerWeek
      }
      topSport {
        id
        associationName
        type
        scale
      }
      ownBusiness {
        id
        name
        beginDate
        endDate
        hoursPerWeek
      }
      volunteerWork {
        id
        associationName
        position
        hoursPerMonth
        partiallyAbroad
      }
      studentTeams {
        id
        name
        position
        employmentType
        hoursPerWeek
        beginDate
        endDate
      }
      skills {
        id
        name
      }
      participation
      tbEventEnrollments {
        id
        tbEvent {
          id
          title: name
        }
        preferredProgramItems {
          id
          company {
            name
          }
          name
          location
          description
          startsAt
          strapiId
          programItemTypeStrapiId
        }
      }
      inhouseDayEnrollments {
        id
        participation
        inhouseDay {
          id
          strapiId
          startDate
          endDate
          time
          location
          title: name
          name
          company {
            name
          }
        }
      }
    #   enrollments {
    #     id
    #     participation
    #     inhouse_day {
    #       id
    #       slug
    #       finalSignupDate
    #       startDate
    #       endDate
    #       time
    #       location
    #       title
    #       company {
    #         name
    #       }
    #     }
    #     tb_event {
    #       id
    #       title
    #     }
    #     preferredProgramItems {
    #       id
    #       company {
    #         name
    #       }
    #       name
    #       location
    #       description
    #       startsAt
    #       programItemType {
    #         color
    #         id
    #         name
    #       }
    #     }
    #   }
    }
  }
`;

const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      jwt
      user {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

const UPDATE_ME = gql`
  mutation($input: UserEditInput!) {
    updateMe(input: $input) {
      id
      postalCode
      streetName
      houseNumber
      city
      qompasVacancyGuide
      qompasCvCheck
    #   bankAccountName
    #   iban
    #   promoCode
    #   reason
      step
      progress
      isComplete
      interestingIndustries {
        id
        name
      }
      highSchoolAverageMark
      highSchoolStartYear
      highSchoolEndYear
      highSchoolName
      highSchoolType
      linkedInProfile
      studies {
        id
        type
        educationalInstitution {
          id
          name
        }
        education {
          id
          name
        }
        finished
        beginDate
        endDate
        nominal
        monthsBehind
      }
      hasJobs
      hasCommittees
      hasBoards
      hasTopSport
      hasOwnBusiness
      hasVolunteerWork
      hasStudentTeam
      jobs {
        id
        companyName
        jobTitle
        type
        beginDate
        endDate
        hoursPerWeek
        partiallyAbroad
      }
      committees {
        id
        association
        name
        position
        employmentType
        beginDate
        endDate
        hoursPerWeek
      }
      boards {
        id
        type
        name
        position
        employmentType
        beginDate
        endDate
        hoursPerWeek
      }
      topSport {
        id
        associationName
        type
        scale
      }
      ownBusiness {
        id
        name
        beginDate
        endDate
        hoursPerWeek
      }
      volunteerWork {
        id
        associationName
        position
        hoursPerMonth
        partiallyAbroad
      }
      studentTeams {
        name
        position
        employmentType
        hoursPerWeek
        beginDate
        endDate
      }
      skills {
        id
      }
      participation
    }
  }
`;

const useAuth = () => {
  const { client, loading, data, updateQuery, refetch } = useBackendQuery<MeData>(ME, {
    errorPolicy: 'ignore',
  });

  const me = data?.me;
  const isAuthenticated = !!me;

  if (!loading && !isAuthenticated && readJWT()) {
    writeJWT(null);
  }

  const [login] = useBackendMutation(LOGIN, {
    onCompleted({ login }) {
      writeJWT(login.jwt);
      client.resetStore();
    },
  });

  const logout = () => {
    writeJWT(null);
    navigate('/');
    client.resetStore();
  };

  const setToken = (jwt: string) => {
    writeJWT(jwt);
    client.resetStore();
  };

  const [updateMe] = useBackendMutation<{ updateMe: User; input: UserInput }>(UPDATE_ME, {
    onCompleted({ updateMe }) {
      updateQuery(() => ({ me: updateMe }));
    },
  });

  const formMe = useMemo(() => {
    if (!me) return me;
    return {
      ...me,
      interestingIndustries: (me.interestingIndustries ?? []).map(industry => industry.id),
      studies: (me.studies ?? []).map(study => {
        const { __typename, ...studyWithoutTypename } = study;
        return {
          ...studyWithoutTypename,
          educational_institution: study.educationalInstitution?.id,
          education: study.education?.id,
          beginDate: moment(study.beginDate),
          endDate: moment(study.endDate),
        };
      }),
      skills: (me.skills ?? []).map(skill => skill.id),
    };
  }, [me]);

  return {
    me,
    formMe,
    updateMe,
    loading,
    isAuthenticated,
    login,
    logout,
    setToken,
    refetch,
  };
};

export default useAuth;
