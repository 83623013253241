import { Button, Col, Row } from 'antd';
import { navigate } from 'gatsby';
import { Link } from 'gatsby';
import React from 'react';
import arrow from '../../images/arrow.svg';
import close from '../../images/close.svg';
import styles from './pickerHeader.module.css';

interface pickerProps {
  backLink?: string,
  closeLink?: string,
};

const PickerHeaderDynamic = ({ backLink, closeLink }: pickerProps) => {
  return <div className={styles.header}>
    <div className="container-sm">
      <Row>
        {backLink ? <Col flex="auto">
          <Link to={backLink}>
            <img className={styles.backArrow} src={arrow} alt="back-arrow" />
          </Link>
        </Col> : ''}
        {closeLink ? <Col flex="34px">
          <Button size="small" type="link">
            <Link to={closeLink}>
              <img className={styles.close} src={close} alt="close-x" />
            </Link>
          </Button>
        </Col> : ''}
      </Row>
    </div>
  </div>
};

export default PickerHeaderDynamic;
