import { CheckCircleTwoTone } from '@ant-design/icons';
import { gql } from '@apollo/client';
import { Button, Checkbox, Col, Form, Row } from 'antd';
import { useStep } from '../../../hooks';
import { useBackendQuery } from '../../../hooks/useBackendQuery';
import { Industry } from '../../../utils/graphqlTypes/backendTypes';
import formStyles from '../../formComponents/formComponents.module.css';
import React from 'react';
interface IndustryData {
  industries: [Industry],
};

interface FormData {
  interestingIndustries: number[],
};

const INDUSTRIES = gql`
  query {
    industries(orderBy: {name: NAME, order: ASC}) {
      id
      name
      imagePath
    }
  }
`;

const Industries = () => {
  const { data } = useBackendQuery<IndustryData>(INDUSTRIES);
  const { initialValues, onFinish } = useStep<FormData>(
    (me) => ({
      interestingIndustries: (me?.interestingIndustries ?? []).map(industry => industry.id),
    }),
  )

  return (
    <Form
      className={formStyles.form}
      initialValues={initialValues}
      onFinish={(values) => {
        onFinish(values as FormData)}
      }
    >
      <h3><span className="turquoise">Welke sectoren vind jij interessant?</span></h3>
      <p>
        Vink hieronder de sectoren aan waar jij wel in zou willen werken. Deze informatie gebruiken
        wij om de beste matches voor jou te zoeken!
      </p>

      <Form.Item name="interestingIndustries" className="checkbox-images">
        <Checkbox.Group>
          <Row gutter={32}>
            {data && data.industries.map(industry => (
              <Col key={industry.id} xs={12} sm={8} md={6}>
                <Checkbox value={industry.id}>
                  <div className="checkbox-images-overlay-wrap">
                    <div className="checkbox-images-overlay"></div>
                    <CheckCircleTwoTone twoToneColor="#00ff7e" />
                  </div>
                  <img src={industry.imagePath} alt="alt" />
                  <p>{industry.name}</p>
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
        >
          Opslaan
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Industries;
