import React, { useMemo } from 'react';
import { Form, Input, Button, Row, Col, Radio  } from 'antd';
import { TopSportScale } from '../../../utils';
import { useAuth, useStep } from '../../../hooks';
import { pick } from '../../../utils';
import formStyles from '../../formComponents/formComponents.module.css';

interface FormData {
  associationName?: string,
  type?: string,
  scale?: TopSportScale,
};

const TopSport = () => {
  const { initialValues, onFinish } = useStep<FormData>(
    (me) => pick(me.topSport, ['id', 'associationName', 'type', 'scale']),
    ({ associationName, type, scale }) => ({ topSport: { id: initialValues.id, associationName, type, scale } })
  )
  return (
    <Form
      className={formStyles.form}
      initialValues={initialValues}
      onFinish={(values) => onFinish(values as FormData)}
    >
      <h3><span className="turquoise">Topsport</span></h3>

      <Form.Item
        label="Naam sportvereniging"
        name="associationName"
        rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
        >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Welke sport?"
        name="type"
        rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
        >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Op welke schaal was dit?"
        name="scale"
        rules={[{ required: true, message: 'Dit is een verplicht veld' }]}
        >
        <Radio.Group className="checkbox-normal">
          <Row gutter={10}>
            <Col lg={8}>
              <Radio value="REGIONAL">Regionaal</Radio>
            </Col>
            <Col lg={8}>
              <Radio value="NATIONAL">Landelijk</Radio>
            </Col>
            <Col lg={8}>
              <Radio value="INTERNATIONAL">Internationaal</Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>

      <Form.Item>
        <Button
          block
          type="primary"
          htmlType="submit"
          >
          Opslaan
        </Button>
      </Form.Item>
    </Form>
  );
}

export default TopSport;
