import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import fetch from 'cross-fetch';
import { promiseToObservable, readJWT } from '../utils';

const cmsLink = new HttpLink({
  uri: '/graphql',
  fetch,
});

const backendLink = new HttpLink({
  uri: '/backend/graphql',
  fetch,
});

const backendAuthLink = setContext((_, { headers }) => {
  const token = readJWT();

  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});

export const useClient = ({ setActiveModal }: { setActiveModal: (modal: string) => Promise<void> }) => {
  const errorLink = onError(({ graphQLErrors, operation, forward }): any => {
    const forbiddenError = graphQLErrors?.find(({ message }) => message === 'Forbidden');
    if (forbiddenError) {
      return promiseToObservable(setActiveModal('login')).flatMap(() => forward(operation));
    }
  });

  return new ApolloClient({
    link: ApolloLink.split(
      operation => operation.getContext().clientName === 'backend',
      errorLink.concat(backendAuthLink).concat(backendLink),
      errorLink.concat(cmsLink)
    ),
    cache: new InMemoryCache(),
  });
};
